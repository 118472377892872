import {
  ReactCompareSliderImage,
  ReactCompareSlider,
} from "react-compare-slider";

function Compare() {
  const comp1 = process.env.PUBLIC_URL + "/img/compare3.jpg";
  const comp2 = process.env.PUBLIC_URL + "/img/compare4.jpg";
  return (
    <div>
      {/* <p>Image Test</p> */}
      {/* Standard usage */}
      <ReactCompareSlider
        itemOne={<ReactCompareSliderImage src={comp1} alt="Image one" />}
        itemTwo={<ReactCompareSliderImage src={comp2} alt="Image two" />}
      />
    </div>
  );
}
export default Compare;
