function Text() {
  return (
    <div className="dark">
      <h1 className="zero">TAKE CONTROL OF THE DARKNESS</h1>
      <h4>LIGHT<span className="boldit">BOOSTER</span> Black Edition</h4>
      <p className="zero">
        Our most advanced beam to date, tuned to deliver 180% more light
        precisely where you need it most. Enhanced with a new triple stage blue
        tint and chrome cap for whiter light.
      </p>
    </div>
  );
}
export default Text;
