import React from "react";

function Images() {
  const H4 = process.env.PUBLIC_URL + "/img/BOX-VISUALS-180-H4-LUCAS-2.png";
  const H7 = process.env.PUBLIC_URL + "/img/BOX-VISUALS-180-H7-LUCAS-2.png";
  const logo = "img/lucas-promo.png";
  return (
    <div className="boxes">
      <div className="bx no-wrap">
        <p>
          <span className="boldit">H4</span> 180%
        </p>
        <p>12V/60/55W</p>
        <p>LLX472DLX2</p>
      </div>
      <div className="bx">
        <img src={H4} alt="" />
      </div>
      <div className="bx">
        <img src={H7} alt="" />
      </div>
      <div className="bx no-wrap">
        <p>
          <span className="boldit">H7</span> 180%
        </p>
        <p>12V/55W</p>
        <p>LLX477DLX2</p>
      </div>
    </div>
  );
}

export default Images;
