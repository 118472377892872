function Video() {
  // const BackVideo = "https://youtu.be/c9jh_Yq9iBQ";
  // const BackVideo = "https://dl8.webmfiles.org/elephants-dream.webm";
  // const BackVideo ="https://www.pexels.com/video/stirring-tea-with-spoon-12173631/";
  // const BackVideo = "video/Lucas LightBooster Black Edition.mp4";
  const BackVideo = process.env.PUBLIC_URL + "/img/lucas2.webp";
  return (
    <div className="box">
      <img src={BackVideo} alt="" />
      {/* <video loop autoplay>
        <source src={BackVideo} type="video/mp4" playsInline />
        Your browser does not support the video tag.
      </video> */}
      {/* <video className="VideoTag">
        <source src={video} type="video/mp4" />
      </video> */}
    </div>
  );
}
export default Video;
