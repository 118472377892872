import React from 'react'

function Car() {
    const car = "img/beam.png";
  return (
    <div className='backcolor'>
        <img src={car} width="40%" alt="" />
    </div>
  )
}

export default Car